import { handleErrorWithSentry } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'
import { dev } from '$app/environment'

Sentry.init({
  dsn: 'https://7d87feddc0387180fbd125f3e9f07226@o139322.ingest.sentry.io/4506599752531968',
  tracesSampleRate: 1.0,
  enabled: dev ? false : true,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  // integrations: [new Replay()],
})

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry()
